.dialogTitle {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 4px;
}

.link {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 16px;
  white-space: nowrap;
}

.copied {
  font-size: 0.8rem;
  margin: 0;
  white-space: nowrap;
}

.qrCode {
  margin-top: 4px;
  margin-bottom: 16px;
}

.dialog {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
}