@font-face {
  font-family: 'BaiJamjuree';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('./fonts/bai-jamjuree-v4-latin-regular.woff2') format('woff2'),
  url('./fonts/bai-jamjuree-v4-latin-regular.woff') format('woff')
}

body {
  margin: 0;
  font-family: "BaiJamjuree", sans-serif;
  background-color: black;
  background-image: url("/bg.png");
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  color: white;
}
