.bottomPanel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottomButtons {
  margin: 16px 8px;
}

.topPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
}

.topPanelTitle {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.waitingForHost {
  margin-top: 12px;
}

.buttonBar {
  text-align: center;
}
