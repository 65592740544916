.bottomPanel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  padding-top: 100vh;
}

.bottomActionRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.yourRoundText {
  font-weight: bold;
  font-size: #{"min(5.5vw, 2rem)"};
  position: absolute;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  top: -1.2rem;
  left: 50%;
}

.yourRoundButton {
  margin: 0 8px;
}

.nameEdit {
  margin-bottom: 12px;
}

.topBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}

.kickButton {
  margin-left: 4px;
}

.playerNameCardWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
}

.playerNameLine {
  margin-top: 4px;
  white-space: nowrap;
}

.myCardRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 2px;
  border-radius: 20px;
}

.myCardRow.available {
  animation: myCardRowGlowFrames 0.6s infinite alternate;
  animation-timing-function: ease-in-out;
}

@keyframes myCardRowGlowFrames {
  from {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 0 16px 2px rgba(255, 255, 255, 0);
  }
  to {
    background-color: rgba(255, 255, 255, 0.46);
    box-shadow: 0 0 16px 2px rgba(255, 255, 255, 0.8);
  }
}

.cardOnDeskWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.currentPlayerUserName {
  animation: glowFrames 0.6s infinite alternate;
  animation-timing-function: ease-in-out;
}

@keyframes glowFrames {
  from {
    text-shadow: 0 0 0 #ffffff;
  }
  to {
    text-shadow: 0 0 6px #ffffff;
  }
}

.rotateForwards {
  animation: rotateFrames 4s linear infinite;
}

.rotateBackwards {
  animation: rotateFrames 4s linear infinite;
  animation-direction: reverse;
}

@keyframes rotateFrames {
  100% {
    transform: rotate(360deg);
  }
}

.directionIndicator {
  width: 60px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: calc(calc(50% - 60px) / 2);
}

.cardDeck {
  position: absolute;
  transform: translate(50%, -50%);
  top: 50%;
  right: calc(calc(50% - 60px) / 2);

  .cardDeckCard {
    margin: 0;
    position: absolute;
  }
}

.cardDeck.available {
  cursor: pointer;
}

.cumulativeDrawText {
  font-size: 1.3rem;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: -2.2rem;
  left: 50%;
}

.dialogTitle {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 16px;
}

.dialog {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
}

.dialog.nameDialog {
  padding: 16px;
}

.chooseColorRow {
  display: flex;
  flex-direction: row;

  div {
    flex-grow: 1;
    height: 50px;
    cursor: pointer;
  }

  div:hover {
    box-shadow: 0 0 20px 0 #FFFFFF;
    z-index: 1100;
  }
}

.dialogButtonBar {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
