.dialog {
  background-color: #363636;
  border-radius: 4px;
  z-index: 1000;
  width: 100vw;
  max-width: 300px;
}

.dialogBackground{
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
