.input {
  font-family: "BaiJamjuree", sans-serif;
  background-color: rgba(255,255,255,0.2);
  color: white;
  padding: 0.2rem 0.2rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 2px solid white;
  outline-width: 0;
  width: 18rem;
}

.input:hover:not(:disabled) {
  box-shadow: 0 0 10px 0 #FFFFFF;
}

.input:focus:not(:disabled) {
  box-shadow: 0 0 10px 0 #FFFFFF;
}
