.button {
  font-family: "BaiJamjuree", sans-serif;
  background-color: white;
  color: black;
  padding: 1.2rem 1rem;
  font-size: 1rem;
  line-height: 0;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  outline-width: 0;
}

.button:hover:not(:disabled) {
  box-shadow: 0 0 10px 0 #FFFFFF;
}

.button:active {
  transform: scale(0.95);
}

.button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.button.compact {
  font-size: 0.8rem;
  padding: 0.6rem 0.3rem;
}
