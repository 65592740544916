.container {
  display: flex;
  flex-direction: row;
  border-radius: 0.167 * 60px;
}

.container.glow {
  animation: glowFrames 0.6s infinite alternate;
  animation-timing-function: ease-in-out;
}

@keyframes glowFrames {
  from {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 0 40px 8px rgba(255, 255, 255, 0);
  }
  to {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 40px 8px #ffffff;
  }
}

.cardBack {
  margin: 0 !important;
  position: absolute;
}
