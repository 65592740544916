.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 8rem;
  margin: 0;
}

.byPega {
  color: white;

  a {
    color: white;
  }

  margin: 0 0 4rem;
}
