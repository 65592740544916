.wrapper {
  display: inline-block;
  margin: 4px 2px;
  user-select: none;
  width: min-content;
}

.card {
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
  transform: translateY(0%) translateZ(0px);
}

.available {
  cursor: pointer;
}
